import type ApplicationInstance from "@ember/application/instance";
import CompoundSurveyEditionComponent from "compound-survey/components/compound-survey/edition";
import CompoundSurveyHistoryTableComponent from "compound-survey/components/compound-survey/history-table";
import AbdominalAreaFormComponent from "core/components/abdominal-area/edition-form";
import ScoreResultPage from "core/components/adherence-risk-prediction/score-result-page";
import AdverseEventEditionForm from "core/components/adverse-events/edition-form";
import AnxietyConsultForm from "core/components/anxiety/consult-form";
import AnxietyEditionForm from "core/components/anxiety/edition-form";
import AnxietySummary from "core/components/anxiety/summary";
import BioExamMonitoringEditionForm from "core/components/bio-exam-monitoring/edition-form";
import BioExamResultsEditionFormComponent from "core/components/bio-exam-results/edition-form";
import BioExamResultsNewEditionFormComponent from "core/components/bio-exam-results/new-edition-form";
import BioExamResultsReviewFormComponent from "core/components/bio-exam-results/review-form";
import BioExamResultsSummaryComponent from "core/components/bio-exam-results/summary";
import CapillarityBloodGlucoseEditionFormComponent from "core/components/capillarity-blood-glucose/edition-form";
import NewCareGiverFormComponent from "core/components/care-giver/new";
import ChatContentComponent from "core/components/chat/content";
import ConstantMonitoringEditionComponent from "core/components/constant-monitoring/edition";
import ConsultationFrequencyEditionForm from "core/components/consultation-frequency/edition-form";
import ConsultationFrequencyPhysicalEditionForm from "core/components/consultation-frequency/physical-edition-form";
import ConsultationFrequencyPsychiatricEditionForm from "core/components/consultation-frequency/psychiatric-edition-form";
import ConsultationEditionFormComponent from "core/components/consultation/edition-form";
import ConsultationReportFormComponent from "core/components/consultation/report-form";
import CrisisPlanFormComponent from "core/components/crisis-plan/edition-form";
import interTreatmentConsultForm from "core/components/ctcae-custom/inter-treatment-consult-form";
import interTreatmentEditionForm from "core/components/ctcae-custom/inter-treatment-edition-form";
import interTreatmentSummary from "core/components/ctcae-custom/inter-treatment-summary";
import PreTreatmentConsultForm from "core/components/ctcae-custom/pre-treatment-consult-form";
import PreTreatmentEditionForm from "core/components/ctcae-custom/pre-treatment-edition-form";
import PreTreatmentSummary from "core/components/ctcae-custom/pre-treatment-summary";
import CtcaeConsultForm from "core/components/ctcae/consult-form";
import CtcaeEditionForm from "core/components/ctcae/edition-form";
import CtcaeSummary from "core/components/ctcae/summary";
import ArticulationsEditionFormComponent from "core/components/das28/articulations-edition-form";
import Das28CdaiEditionFormComponent from "core/components/das28/das28-cdai-edition-form";
import Das28CrpEditionFormComponent from "core/components/das28/das28-crp-edition-form";
import das28History from "core/components/das28/das28-history";
import das28SdaiEditionForm from "core/components/das28/das28-sdai-edition-form";
import das28VsEditionForm from "core/components/das28/das28-vs-edition-form";
import PRFeelingEvasMedicFormComponent from "core/components/das28/medic-feeling-edition-form";
import PRFeelingEvasPatientFormComponent from "core/components/das28/patient-feeling-edition-form";
import DiabetowiseDischargeEditionFormComponent from "core/components/diabetowise-discharge/edition-form";
import DigitalCareFormComponent from "core/components/digital-care/edition-form";
import DisenrollmentForm from "core/components/disenrollment/form";
import DisorderHistoryEditionFormComponent from "core/components/disorder-history/edition-form";
import DocumentEditionFormComponent from "core/components/document/edition-form";
import DpCycleEditionFormComponent from "core/components/dp-cycle/edition-form";
import FactLymEditionFormComponent from "core/components/fact-lym/edition-form";
import FeelingEvasFormComponent from "core/components/feeling-evas/form";
import FileUploadedEditionFormComponent from "core/components/file-uploaded/edition-form";
import HemodialysisSessionEditionFormComponent from "core/components/hemodialysis-session/edition-form";
import HospitalisationRiskPredictionScoreResultPageComponent from "core/components/hospitalisation-risk-prediction/score-result-page";
import RhumatowiseInfectionEditionFormComponent from "core/components/infection/edition-form";
import InflammatoryEruptionEditionFormComponent from "core/components/inflammatory-eruption/edition-form";
import LifestylCoachingEditionComponent from "core/components/lifestyle/coaching-edition-form";
import LifestyleEditionComponent from "core/components/lifestyle/edition";
import GoalsEditionComponent from "core/components/lifestyle/goals-edition-form";
import JobsEditionComponent from "core/components/lifestyle/jobs-edition-form";
import MammographyEditionFormComponent from "core/components/mammography/edition-form";
import HeightEditionFormComponent from "core/components/measurements/height-edition-form";
import MeasurementsResults from "core/components/measurements/results";
import WeightEditionFormComponent from "core/components/measurements/weight-edition-form";
import MedicalEventEditionFormComponent from "core/components/medical-event/edition-form";
import MedicinesReconciliationFormComponent from "core/components/medicines-reconciliation/edition-form";
import MiciCrohnSurveyConsultForm from "core/components/mici/crohn-survey-consult-form";
import MiciCrohnSurveyEditionForm from "core/components/mici/crohn-survey-edition-form";
import MiciCrohnSurveySummary from "core/components/mici/crohn-survey-summary";
import MiciIbdDiskSurveyConsultForm from "core/components/mici/ibd-disk-survey-consult-form";
import MiciIbdDiskSurveyConsultFormDetails from "core/components/mici/ibd-disk-survey-consult-form-details";
import MiciIbdDiskSurveyEditionForm from "core/components/mici/ibd-disk-survey-edition-form";
import MiciIbdDiskSurveySummary from "core/components/mici/ibd-disk-survey-summary";
import MiciRchSurveyConsultForm from "core/components/mici/rch-survey-consult-form";
import MiciRchSurveyEditionForm from "core/components/mici/rch-survey-edition-form";
import MiciRchSurveySummaryComponent from "core/components/mici/rch-survey-summary";
import ReportEditionFormComponent from "core/components/online-consultation/report-edition-form";
import OxygeneSaturationEditionFormComponent from "core/components/oxygene-saturation/edition-form";
import PersonalBackgroundFormComponent from "core/components/personal-background/form";
import PersonalNotesEditionFormComponent from "core/components/personal-notes/edition-form";
import AskForPrescription from "core/components/prescription/ask-for-prescription";
import BioExamPrescriptionEditionFormComponent from "core/components/prescription/bio-exam-edition-form";
import MedicalImagingEditionFormComponent from "core/components/prescription/medical-imaging-edition-form";
import PrescriptionMedicationEditionForm from "core/components/prescription/medication-edition-form";
import ParamedicalCareEditionFormComponent from "core/components/prescription/paramedical-care-edition-form";
import MrcReplacementEditionFormComponent from "core/components/replacement/edition-form";
import RespiratoryRateEditionFormComponent from "core/components/respiratory-rate/edition-form";
import BdiIiEditionFormComponent from "core/components/scale-surveys/bdi-ii-edition-form";
import FastEditionFormComponent from "core/components/scale-surveys/fast-edition-form";
import ScaleSurveysHistory from "core/components/scale-surveys/history";
import PhqNineEditionFormComponent from "core/components/scale-surveys/phq-nine-edition-form";
import QidsEditionFormComponent from "core/components/scale-surveys/qids-edition-form";
import YMRSEditionComponent from "core/components/scale-surveys/ymrs-edition-form";
import StoolsNumberEditionFormComponent from "core/components/stools-number/edition-form";
import TaskPlanificationComponent from "core/components/task/edition-form";
import TemperatureEditionFormComponent from "core/components/temperature/edition-form";
import TherapeuticAssistanceEditionFormComponent from "core/components/therapeutic-assistance/edition-form";
import ThymicEpisodeForm from "core/components/thymic-episode/edition-form";
import ToxicityRiskPredictionScoreResultPageComponent from "core/components/toxicity-risk-prediction/score-result-page";
import TransmissionEditionComponent from "core/components/transmission/edition";
import TreatmentHistoryEditionFormComponent from "core/components/treatment-history/edition-form";
import TreatmentBaseEditionFormComponent from "core/components/treatment/base-edition-form";
import VemsEditionFormComponent from "core/components/vems/edition-form";
import MedicalHistoryEditionFormComponent from "parog-web/components/medical-history/edition-form";
import KfreEditionComponent from "pro-irc/components/kfre/edition-form";

const ForcComponents = {
  "abdominal-area/edition-form": AbdominalAreaFormComponent,
  "adherence-risk-prediction/score-result-page": ScoreResultPage,
  "adverse-events/edition-form": AdverseEventEditionForm,
  "anxiety/consult-form": AnxietyConsultForm,
  "anxiety/edition-form": AnxietyEditionForm,
  "anxiety/summary": AnxietySummary,
  "bio-exam-monitoring/edition-form": BioExamMonitoringEditionForm,
  "bio-exam-results/edition-form": BioExamResultsEditionFormComponent,
  "bio-exam-results/new-edition-form": BioExamResultsNewEditionFormComponent,
  "bio-exam-results/review-form": BioExamResultsReviewFormComponent,
  "bio-exam-results/summary": BioExamResultsSummaryComponent,
  "capillarity-blood-glucose/edition-form":
    CapillarityBloodGlucoseEditionFormComponent,
  "care-giver/new": NewCareGiverFormComponent,
  "chat/content": ChatContentComponent,
  "compound-survey/edition": CompoundSurveyEditionComponent,
  "compound-survey/history-table": CompoundSurveyHistoryTableComponent,
  "constant-monitoring/edition": ConstantMonitoringEditionComponent,
  "consultation-frequency/edition-form": ConsultationFrequencyEditionForm,
  "consultation-frequency/psychiatric-edition-form":
    ConsultationFrequencyPsychiatricEditionForm,
  "consultation-frequency/physical-edition-form":
    ConsultationFrequencyPhysicalEditionForm,
  "consultation/edition-form": ConsultationEditionFormComponent,
  "consultation/report-form": ConsultationReportFormComponent,
  "crisis-plan/edition-form": CrisisPlanFormComponent,
  "ctcae/consult-form": CtcaeConsultForm,
  "ctcae/edition-form": CtcaeEditionForm,
  "ctcae/summary": CtcaeSummary,
  "ctcae-custom/inter-treatment-consult-form": interTreatmentConsultForm,
  "ctcae-custom/inter-treatment-edition-form": interTreatmentEditionForm,
  "ctcae-custom/inter-treatment-summary": interTreatmentSummary,
  "ctcae-custom/pre-treatment-consult-form": PreTreatmentConsultForm,
  "ctcae-custom/pre-treatment-edition-form": PreTreatmentEditionForm,
  "ctcae-custom/pre-treatment-summary": PreTreatmentSummary,
  "das28/articulations-edition-form": ArticulationsEditionFormComponent,
  "das28/das28-cdai-edition-form": Das28CdaiEditionFormComponent,
  "das28/das28-crp-edition-form": Das28CrpEditionFormComponent,
  "das28/das28-sdai-edition-form": das28SdaiEditionForm,
  "das28/das28-vs-edition-form": das28VsEditionForm,
  "das28/das28-history": das28History,
  "das28/medic-feeling-edition-form": PRFeelingEvasMedicFormComponent,
  "das28/patient-feeling-edition-form": PRFeelingEvasPatientFormComponent,
  "diabetowise-discharge/edition-form":
    DiabetowiseDischargeEditionFormComponent,
  "digital-care/edition-form": DigitalCareFormComponent,
  "disenrollment/form": DisenrollmentForm,
  "disorder-history/edition-form": DisorderHistoryEditionFormComponent,
  "dp-cycle/edition-form": DpCycleEditionFormComponent,
  "document/edition-form": DocumentEditionFormComponent,
  "fact-lym/edition-form": FactLymEditionFormComponent,
  "feeling-evas/form": FeelingEvasFormComponent,
  "file-uploaded/edition-form": FileUploadedEditionFormComponent,
  "hemodialysis-session/edition-form": HemodialysisSessionEditionFormComponent,
  "hospitalisation-risk-prediction/score-result-page":
    HospitalisationRiskPredictionScoreResultPageComponent,
  "infection/edition-form": RhumatowiseInfectionEditionFormComponent,
  "inflammatory-eruption/edition-form":
    InflammatoryEruptionEditionFormComponent,
  "kfre/edition-form": KfreEditionComponent,
  "lifestyle/coaching-edition-form": LifestylCoachingEditionComponent,
  "lifestyle/edition": LifestyleEditionComponent,
  "lifestyle/goals-edition-form": GoalsEditionComponent,
  "lifestyle/jobs-edition-form": JobsEditionComponent,
  "mammography/edition-form": MammographyEditionFormComponent,
  "mammography-and-mri/edition-form": MammographyEditionFormComponent,
  "measurements/height-edition-form": HeightEditionFormComponent,
  "measurements/results": MeasurementsResults,
  "measurements/weight-edition-form": WeightEditionFormComponent,
  "medical-event/edition-form": MedicalEventEditionFormComponent,
  "medical-history/edition-form": MedicalHistoryEditionFormComponent,
  "medicines-reconciliation/edition-form": MedicinesReconciliationFormComponent,
  "mici/crohn-survey-consult-form": MiciCrohnSurveyConsultForm,
  "mici/crohn-survey-edition-form": MiciCrohnSurveyEditionForm,
  "mici/crohn-survey-summary": MiciCrohnSurveySummary,
  "mici/ibd-disk-survey-consult-form-details":
    MiciIbdDiskSurveyConsultFormDetails,
  "mici/ibd-disk-survey-consult-form": MiciIbdDiskSurveyConsultForm,
  "mici/ibd-disk-survey-edition-form": MiciIbdDiskSurveyEditionForm,
  "mici/ibd-disk-survey-summary": MiciIbdDiskSurveySummary,
  "mici/rch-survey-consult-form": MiciRchSurveyConsultForm,
  "mici/rch-survey-edition-form": MiciRchSurveyEditionForm,
  "mici/rch-survey-summary": MiciRchSurveySummaryComponent,
  "online-consultation/report-edition-form": ReportEditionFormComponent,
  "oxygene-saturation/edition-form": OxygeneSaturationEditionFormComponent,
  "personal-background/form": PersonalBackgroundFormComponent,
  "personal-notes/edition-form": PersonalNotesEditionFormComponent,
  "prescription/ask-for-prescription": AskForPrescription,
  "prescription/bio-exam-edition-form": BioExamPrescriptionEditionFormComponent,
  "prescription/medical-imaging-edition-form":
    MedicalImagingEditionFormComponent,
  "prescription/medication-edition-form": PrescriptionMedicationEditionForm,
  "prescription/paramedical-care-edition-form":
    ParamedicalCareEditionFormComponent,
  "replacement/edition-form": MrcReplacementEditionFormComponent,
  "respiratory-rate/edition-form": RespiratoryRateEditionFormComponent,
  "scale-surveys/bdi-ii-edition-form": BdiIiEditionFormComponent,
  "scale-surveys/fast-edition-form": FastEditionFormComponent,
  "scale-surveys/phq-nine-edition-form": PhqNineEditionFormComponent,
  "scale-surveys/qids-edition-form": QidsEditionFormComponent,
  "scale-surveys/ymrs-edition-form": YMRSEditionComponent,
  "scale-surveys/history": ScaleSurveysHistory,
  "stools-number/edition-form": StoolsNumberEditionFormComponent,
  "task/edition-form": TaskPlanificationComponent,
  "temperature/edition-form": TemperatureEditionFormComponent,
  "therapeutic-assistance/edition-form":
    TherapeuticAssistanceEditionFormComponent,
  "thymic-episode/edition-form": ThymicEpisodeForm,
  "toxicity-risk-prediction/score-result-page":
    ToxicityRiskPredictionScoreResultPageComponent,
  "transmission/edition": TransmissionEditionComponent,
  "treatment-history/edition-form": TreatmentHistoryEditionFormComponent,
  "treatment/base-edition-form": TreatmentBaseEditionFormComponent,
  "vems/edition-form": VemsEditionFormComponent
};

type ParogWebApplicationInstance = ApplicationInstance & {
  forcComponents: typeof ForcComponents;
};

export function initialize(owner: ParogWebApplicationInstance): void {
  owner.forcComponents = ForcComponents;
}

export default {
  initialize
};
